import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FormItemGenerator from "./formItemGenerator";
import { QuesDict } from "./questions";

import API from "../../../../../axios";
import { useSetState } from "../../../../../Hooks/useSetState";

const ANS_JournalBearing = "Journal Bearing";
const ANS_AntiFrictionBearing = "Anti-Friction Bearing";
const ANS_Unknown = "Unknown";
const ANS_CrankCase = "Crank Case";
const ANS_CylinderCase = "Cylinder Case";
const ANS_50HZ = "50 Hz";
const ANS_60HZ = "60 Hz";

const OptionBearingType = [
  { value: "0", label: ANS_JournalBearing },
  { value: "1", label: ANS_AntiFrictionBearing },
  { value: "2", label: ANS_Unknown },
];
const OptionInstallType = [
  { value: "0", label: ANS_CrankCase },
  { value: "1", label: ANS_CylinderCase },
];

const ElectricalLineFreq = [
  { value: "50", label: ANS_50HZ },
  { value: "60", label: ANS_60HZ },
];

const initOptionList = {
  OptionBearingType,
  OptionInstallType,
  OptionBearingList: [],
  ElectricalLineFreq,
};

const initState = {
  isLoading: false,
};

// Style
const labelCol = { span: 8 };
const wrapperCol = { span: 16 };
const initialValues = { remember: true };
const autoComplete = "off";
const offset = 8;

const DIYQuest = ({ ...props }) => {
  const [optionList, setOptionList] = useSetState(initOptionList);
  const [state, setState] = useSetState(initState);

  const [form] = Form.useForm();

  const onMount = async () => {
    const { equipmentId, tagId, componentType, componentSubType } =
      props.component;

    setState({ isLoading: true });
    try {
      const response = await Promise.all([
        API.equipConfig_details(equipmentId),
        API.componentConfig_details(tagId),
      ]);

      let equipmentConfig = {};
      if (response[0] && response[0].status === 200) {
        equipmentConfig = response[0].data.data;
      }

      let componentConfig = {};
      if (response[1] && response[1].status === 200) {
        componentConfig = response[1].data.data;
      }

      if (Object.keys(componentConfig).length > 0) {
        const config = getAnswer({
          equipmentConfig,
          componentConfig,
          componentType,
          componentSubType,
        });

        form.setFieldsValue(config);
      }

      setState({
        isLoading: false,
        equipmentConfig,
        componentConfig,
        componentType,
        componentSubType,
      });
      // setOptionList({ OptionBearingList });
    } catch (error) {
      console.log("onMount:", { error });
      setState({ isLoading: false });
    }
  };
  useEffect(() => {
    onMount();
  }, []);

  const getAnswer = ({
    equipmentConfig,
    componentConfig,
    componentType,
    componentSubType,
  }) => {
    const { isConstantSpeed } = equipmentConfig;

    if (
      typeof isConstantSpeed !== "boolean" ||
      !componentConfig ||
      !componentConfig.config ||
      !componentConfig.config[componentType] ||
      !componentConfig.config[componentType][componentSubType]
    )
      return;

    const config = componentConfig.config[componentType][componentSubType];

    let data = {};

    quesList(componentType, componentSubType, isConstantSpeed).forEach(
      ({ defaultAnswer, quesID }) => {
        if (typeof defaultAnswer !== "function") return;

        data[quesID] =
          typeof defaultAnswer(config) === "number"
            ? defaultAnswer(config).toString()
            : defaultAnswer(config) === "null"
            ? "NA"
            : defaultAnswer(config); //bugFix: some values coming as number so not getting set
      }
    );

    return data;
  };

  const getConfig = (answerList) => {
    const { componentType, componentSubType } = state;
    let data = {};

    getQuesArr().forEach(({ generateConfig, quesID }) => {
      if (typeof generateConfig !== "function") return;
      if (!answerList[quesID]) return;

      generateConfig(answerList[quesID], data);
    });

    return { [componentType]: { [componentSubType]: data.config } };
  };

  const onFinish = async (answerList) => {
    const config = getConfig(answerList);

    console.log("Success:", { answerList, config });

    const { componentType, componentSubType, componentConfig } = state;
    let allowFaultUpdate = false;
    if (
      answerList.qID_4 &&
      componentConfig &&
      componentConfig.config &&
      componentConfig.config[componentType] &&
      componentConfig.config[componentType][componentSubType] &&
      componentConfig.config[componentType][componentSubType].bearing
        .bearingType != +answerList.qID_4
    ) {
      allowFaultUpdate = true;
    } else if (
      answerList.qID_5 &&
      componentConfig.config &&
      answerList.qID_5.length !==
        componentConfig.config[componentType][componentSubType].bearing
          .antiFriction.length
    ) {
      allowFaultUpdate = true;
    } else if (
      answerList.qID_11 &&
      componentConfig &&
      componentConfig.config &&
      answerList.qID_11 !=
        componentConfig.config[componentType][componentSubType].mountLoc
    ) {
      allowFaultUpdate = true;
    } else if (answerList.qID_5) {
      const selectedBearingNo = config[componentType][
        componentSubType
      ].bearing.antiFriction.map((ele) => `${ele.bearingNumber}${ele.bearingMake}`);
      componentConfig.config &&
        componentConfig.config[componentType][
          componentSubType
        ].bearing.antiFriction.map((ele) => {
          if (!selectedBearingNo.includes(`${ele.bearingNumber}${ele.bearingMake}`)) {
            allowFaultUpdate = true;
          }
        });
    }else if (
      answerList.qID_6 &&
      componentConfig.config &&
      config[componentType][componentSubType] && 
      componentConfig.config[componentType][componentSubType] &&
      config[componentType][componentSubType].length !==
        componentConfig.config[componentType][componentSubType].length
    ) {
      allowFaultUpdate = true;
    }else if (answerList.qID_6){
      const selectedBearingNo = config[componentType][
        componentSubType
      ].map((ele) => `${ele.bearingNumber}${ele.shaftBearingMake}`);
      componentConfig.config &&
        componentConfig.config[componentType][
          componentSubType
        ].map((ele) => {
          if (!selectedBearingNo.includes(`${ele.bearingNumber}${ele.shaftBearingMake}`)) {
            allowFaultUpdate = true;
          }
        });
    }
    const { tagId } = props.component;

    let response;

    const isConfigured = await API.configTypeDetails(tagId);
    if (
      isConfigured.data.data.isOldConfig == false &&
      isConfigured.data.data.isDIYConfig == false
    ) {
      response = await API.componentConfig_create({ config, tagId });
    } else {
      let configCopy = { config };
      if (
        allowFaultUpdate ||
        (componentConfig && Object.keys(componentConfig).length === 0)
      ) {
        configCopy = { ...configCopy, allowFaultUpdate: true };
      }
      response = await API.componentConfig_update(tagId, { ...configCopy });
    }
    if (response && response.status === 200) {
      toast.success("Success");
      props.closeModal();
    } else {
      response && toast.error(response.data.data);
    }
  };

  const onFinishFailed = (errorInfo) => {
    const config = getConfig(errorInfo.values);
    console.log("Failed:", { errorInfo, config });
  };

  const FormItemWrap = ({ form, option, ...props }) => {
    const { getFieldValue = () => null, setFieldsValue = () => null } = form;
    const { rules, hidden, dependencies } = option;

    if (typeof rules === "function")
      props.rules = rules({ getFieldValue, setFieldsValue });

    const Element = () => <Form.Item {...props} />;

    if (typeof hidden === "function" && typeof dependencies === "string") {
      return (
        <Form.Item
          noStyle
          shouldUpdate={(pVal, cVal) =>
            pVal[dependencies] !== cVal[dependencies]
          }
        >
          {() =>
            hidden({ getFieldValue, setFieldsValue }) ? <Element /> : null
          }
        </Form.Item>
      );
    }

    return <Element />;
  };

  const onChange = (e, object) => {
    // onChnage any input in DIY add Logic here.
    if (object && object.name === "qID_5" && object.action === "select-option") {
      console.log(form.getFieldValue("qID_5"));
      const selectedBearing = form.getFieldValue("qID_5");
      let setBearing = selectedBearing;
      if (
        selectedBearing &&
        selectedBearing.length &&
        selectedBearing[selectedBearing.length - 1].label === "NA"
      ) {
        setBearing = [selectedBearing[selectedBearing.length - 1]];
      } else if (selectedBearing && selectedBearing.length) {
        setBearing = selectedBearing.filter((ele) => ele.label !== "NA");
      }
      form.setFieldsValue({ qID_5: setBearing });
    }
  };

  const quesList = (componentType, componentSubType, isConstantSpeed) =>
    QuesDict[componentType][componentSubType]({ isConstantSpeed });

  const getQuesArr = () => {
    const { componentType, componentSubType, equipmentConfig } = state;

    if (!componentType || !componentSubType || !equipmentConfig) return [];
    const { isConstantSpeed } = equipmentConfig;

    return quesList(componentType, componentSubType, isConstantSpeed);
  };

  // useEffect()//1st API equipment config, mount configuration,

  if (state.isLoading) return <div>Loading...</div>;

  const { equipmentConfig } = state;
  let isConstantSpeed = false;
  if (equipmentConfig) {
    isConstantSpeed = equipmentConfig.isConstantSpeed;
  }
  const getBearingList = async (inputValue) => {
    if (inputValue.length >= 2) {
      const response = await API.bearing_list(inputValue);
      if (response && response.status && response.status === 200) {
        const bearingData = response.data && response.data.data;
        const OptionBearingListTemp = bearingData.map((bearing) => ({
          value: `${bearing.BearingNumber} || ${bearing.BearingMake}`,
          label: `${bearing.BearingNumber} || ${bearing.BearingMake}`,
        }));
        return [
          {
            label: "NA",
            value: "NA",
          },
          ...OptionBearingListTemp,
        ];
      }
      return [{
        label: 'NA',
        value: 'NA'
      }];
    }
    return [{
      label: 'NA',
      value: 'NA'
    }];
  };

  const loadOptions = (
    inputValue
  ) => new Promise((resolve) => {
      resolve(getBearingList(inputValue));
  });

  const handleSelectChange = (selectedOption, { action }) => {
    console.log("Selected option in FormItemGen:", selectedOption, action);
    // if (action === "input-change") {
    //   getBearingList(selectedOption);
    // }
  };

  return (
    <Form
      {...{
        form,
        // labelCol,
        wrapperCol,
        initialValues,
        autoComplete,
        onFinish,
        onFinishFailed,
      }}
    >
      {getQuesArr().map(({ quesID: name, ques: label, option, type }) => {
        return (
          <FormItemWrap {...{ name, type, option, form, label }}>
            <FormItemGenerator
              {...{
                type,
                option,
                optionList,
                isConstantSpeed,
                onChange,
                name,
                handleSelectChange,
                loadOptions,
              }}
            />
          </FormItemWrap>
        );
      })}

      <Form.Item wrapperCol={{ ...wrapperCol, offset }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DIYQuest;
